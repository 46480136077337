import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Icon,
  Link,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import BusinessIcon from '@material-ui/icons/Business'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import GA from '../../service/GoogleAnalytics'
import Footer from '../appbar/footer'
import './carousel.overrides.css'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    margin: 'auto',
    maxWidth: '100%',
    color: theme.palette.primary.main,
    minHeight: 'calc(100vh - 175px)'
  },
  grid: {
    marginTop: theme.spacing(2),
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  backContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  companyContainer: {
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  header: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  information: {
    marginBottom: theme.spacing(2)
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    margin: 'auto',
    background: 'rgba(255, 255, 255, 0.9)'
  },
  logo: {
    width: 100,
    height: 100,
    objectFit: 'cover'
  },
  carousel: {
    maxWidth: '100%',
    margin: 'auto'
  },
  contactButton: {
    minWidth: '170px',
    textTransform: 'capitalize',
    borderRadius: 25,
    fontSize: '1rem',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  buttons: {
    marginTop: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: '15%',
    width: '70%',
    minWidth: '170px',
    textTransform: 'capitalize',
    borderRadius: 25,
    fontSize: '1rem'
  }
}))

export interface ProductValues {
  name: string
  description: string
  features?: string
  useCases: string
  vettingLevel: string
  scrm: string
  images: string[]
  pdfUrl?: string
  demoUrl?: string
  location?: string
  companyId: string
  companyName: string
  website: string
  companyLogoUrl: string
}

interface Props {
  values: ProductValues
  error?: string
  loading: boolean
}

const ProductScreen: React.FC<Props> = (props: Props) => {
  const { values, loading, error } = props
  const history = useHistory()
  const classes = useStyles()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  var vettingShieldIcon = ''

  if (values.vettingLevel === 'Vetted') {
    vettingShieldIcon = '/shield_green_s.png'
  } else if (values.vettingLevel === 'Unvetted') {
    vettingShieldIcon = '/shield_red_s.png'
  } else {
    vettingShieldIcon = '/shield_yellow_s.png'
  }

  const getScreeningLevel = (vettingLevel: string) => {
    if (vettingLevel === 'Vetted') {
      return 'Pre-screened'
    } else if (vettingLevel === 'Unvetted') {
      return 'Not Pre-screened'
    } else {
      return 'Pre-screened, Concerns Documented'
    }
  }

  const companyAvatar = values.companyLogoUrl ? (
    <Avatar className={classes.logo} variant="square">
      <img className={classes.logo} src={values.companyLogoUrl} />
    </Avatar>
  ) : (
    <Avatar className={classes.logo} variant="square">
      <BusinessIcon />
    </Avatar>
  )

  const handleClick = (type: string): void => {
    GA.sendEvent({
      category: 'Product',
      action: `${type} Link`
    })
  }

  return (
    <>
      <div className={classes.root}>
        {error && <Alert severity="error">{error}</Alert>}
        {loading ? (
          <div style={{ textAlign: 'center', margin: 4 }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            justify={isMobile ? 'center' : 'flex-start'}
            alignItems="flex-start"
            className={classes.grid}
            style={{ maxWidth: '100%' }}
          >
            <Grid item xs={12} className={classes.backContainer}>
              <Button
                onClick={(): void => history.goBack()}
                color="secondary"
                style={{
                  textTransform: 'none',
                  marginBottom: 22
                }}
                startIcon={<ArrowBackIosIcon />}
              >
                Back to Results
              </Button>
            </Grid>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                lg={6}
                xl={3}
                className={classes.companyContainer}
              >
                <Grid item xs={12}>
                  <Card className={classes.companyInfo} color="secondary">
                    <CardHeader
                      avatar={
                        <RouterLink to={`/company/${values.companyId}`}>
                          {companyAvatar}
                        </RouterLink>
                      }
                      title={
                        <Typography variant="h5">
                          <RouterLink
                            to={`/company/${values.companyId}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            {values.companyName}
                          </RouterLink>
                        </Typography>
                      }
                      subheader={
                        <div>
                          <Typography>
                            <strong>Screening Level:</strong>
                          </Typography>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Typography style={{ marginRight: '4px' }}>
                              {getScreeningLevel(values.vettingLevel)}
                            </Typography>
                            <Icon>
                              <img src={vettingShieldIcon} />
                            </Icon>
                          </div>
                          <Typography>
                            <strong>Location:</strong>
                          </Typography>
                          <Typography>{values.location}</Typography>
                          <Link
                            href={values.website}
                            target="_blank"
                            color="secondary"
                          >
                            {values.website}
                          </Link>
                        </div>
                      }
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} className={classes.carousel}>
                  {values.images.length !== 0 ? (
                    <Carousel showStatus={false} width="100%">
                      {values.images.map((image) => (
                        <div key={image}>
                          <img src={image} alt={values.name} />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <Carousel showStatus={false} width="350px">
                      <div>
                        <img src="/placeholder.svg" alt={values.name} />
                      </div>
                      <div>
                        <img src="/placeholder.svg" alt={values.name} />
                      </div>
                    </Carousel>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item xs={12}>
                    <Typography variant="h3">{values.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      href="mailto:marquis@opescyber.com.au"
                      target="_blank"
                      className={classes.contactButton}
                      onClick={() => handleClick('I want this')}
                    >
                      <Box fontWeight="fontWeightBold">I want this</Box>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.header}>
                      Description
                    </Typography>
                    <Typography variant="body1" className={classes.information}>
                      {values.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.header}>
                      Use Cases
                    </Typography>
                    <Typography variant="body1" className={classes.information}>
                      {values.useCases}
                    </Typography>
                  </Grid>
                  {values.features && (
                    <Grid item xs={12}>
                      <Typography variant="h5" className={classes.header}>
                        Features
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.information}
                      >
                        {values.features}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.header}>
                      Supply Chain Risk Management
                    </Typography>
                    <Typography variant="body1" className={classes.information}>
                      {values.scrm}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className={classes.buttons}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ maxWidth: '100%' }}
                  >
                    {values.demoUrl && (
                      <Grid item xs={12} lg={4} xl={3}>
                        <Button
                          target="_blank"
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          href={values.demoUrl}
                          onClick={() => handleClick('View Demo')}
                        >
                          <Box fontWeight="fontWeightBold">View Demo</Box>
                        </Button>
                      </Grid>
                    )}
                    {values.pdfUrl && (
                      <Grid item xs={12} lg={4} xl={3}>
                        <Button
                          target="_blank"
                          className={classes.button}
                          variant="contained"
                          color="primary"
                          href={values.pdfUrl}
                          onClick={() => handleClick('Data Sheet PDF')}
                        >
                          <Box fontWeight="fontWeightBold">Data Sheet PDF</Box>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
      <Footer />
    </>
  )
}

export default ProductScreen
