import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import Footer from '../modules/appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    },
    padding: theme.spacing(4),
    minHeight: 'calc(100vh - 200px)'
  },
  header: {
    marginBottom: theme.spacing(4)
  },
  terms: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  }
}))

interface Props {}

const TermsPage: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" color="primary" className={classes.header}>
          Terms of Use
        </Typography>
        <Grid container spacing={3}>
          <Grid container xs={12} lg={6}>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
              I. Proprietary Information
              </Typography>
              <Typography color="primary">
                Demo Instance.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                II. Third Party Components
              </Typography>
              <Typography color="primary">
                You acknowledge and agree that the Marquis Marketplace may contain open
                source software or other third party software components that
                are licensed under the terms of an open source license or
                agreement with an applicable third party licensor, and that no
                right, title or interest in or to ant Third Party Components or
                any intellectual property rights therein are transferred to you
                by way of this agreement. These Third Party Components are
                provided to you on an “AS IS” BASIS WITHOUT WARRANTIES OF ANY
                KIND unless specified otherwise. However, such Third Party
                Components may carry their own warranties and license that shall
                pass through to you to the extent authorized . Exercise of such
                warranty will be directly between you and the applicable third
                party provider.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                III. Representations and Warranties
              </Typography>
              <Typography color="primary">
                TBD
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} lg={6}>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                IV. Privacy and User Content
              </Typography>
              <Typography color="primary">
                TBD
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                V. Limits on Use
              </Typography>
              <Typography color="primary">
                While accessing the Marquis Marketplace, you are prohibited from
                engaging in any illegal conduct or conduct that may threaten or
                system of network security or expose us or any of our users to
                any liability or potential harm.
              </Typography>
            </Grid>
            <Grid item className={classes.terms}>
              <Typography variant="h6" color="secondary">
                VI. Governing Law and Venue
              </Typography>
              <Typography color="primary">
                TBD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  )
}

export default TermsPage
