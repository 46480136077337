import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(8),
    textAlign: 'left'
  }
}))

interface Props {}

const Footer: React.FC<Props> = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="body2" color="primary">
        Marquis Demo 2022
      </Typography>
      <Typography
        component={RouterLink}
        to="/terms"
        variant="body2"
        color="primary"
      >
        Terms and Conditions
      </Typography>
    </div>
  )
}

export default Footer
