import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MUILink from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Alert from '@material-ui/lab/Alert'
import { FormikErrors } from 'formik'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Footer from '../appbar/footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    },
    minHeight: 'calc(100vh - 175px)'
  },
  subtitle: {
    marginTop: theme.spacing(2)
  },
  grid: {
    marginTop: theme.spacing(2),
    width: 500,
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  mainForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    right: theme.spacing(0),
    textTransform: 'capitalize',
    minWidth: 100,
    borderRadius: 25,
    margin: theme.spacing(1),
    fontSize: '1rem'
  },

  textField: {
    background: theme.palette.primary.main
  },

  adornedEnd: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main
    },
    '&:active': {
      background: theme.palette.primary.main
    },
    '&:focus': {
      background: theme.palette.primary.main
    }
  }
}))

export interface SignInFormValues {
  email?: string
  password?: string
}

interface Props {
  values: SignInFormValues
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  isSigningIn: boolean
  errors?: FormikErrors<SignInFormValues>
  status?: any
  setStatus?(status: any): void
}

const SignInScreen: React.FC<Props> = (props: Props) => {
  const { values, onChange, isSigningIn, errors, status } = props
  const { email, password } = values
  const classes = useStyles()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  return (
    <>
      <div className={classes.root}>
        {status?.error && !status.success && (
          <Alert severity="error">{status.error}</Alert>
        )}
        <Grid
          className={classes.grid}
          container
          spacing={3}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography color="primary" variant="h4">
              Welcome to the Marquis Marketplace.
            </Typography>
            <Typography
              color="primary"
              variant="subtitle1"
              className={classes.subtitle}
            >
              Enter your credentials to get started. If you have requested to
              become a member, please check your inbox for instructions on how
              to access your account.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.mainForm}>
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                color="secondary"
                id="email"
                type="email"
                label="Email Address"
                variant="filled"
                autoComplete="email"
                value={email}
                onChange={onChange}
                error={Boolean(errors?.email)}
                helperText={errors?.email}
              />
              <TextField
                fullWidth
                inputProps={{
                  className: classes.textField
                }}
                color="secondary"
                id="password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                variant="filled"
                autoComplete="password"
                value={password}
                onChange={onChange}
                error={Boolean(errors?.password)}
                helperText={errors?.password}
                InputProps={{
                  classes: {
                    adornedEnd: classes.adornedEnd
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                className={classes.button}
                variant="contained"
                color="primary"
                disabled={isSigningIn}
              >
                <Box fontWeight="fontWeightBold">Login</Box>
              </Button>
              <MUILink
                to="/reset-password"
                component={RouterLink}
                color="primary"
              >
                Forgot password?
              </MUILink>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  )
}

export default SignInScreen
